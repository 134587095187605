@import "../style/color.scss";
@import "../style/breakpoints.scss";
@import "../style/variables.scss";

div#activity {
	div.feed {
		display: flex;
		flex-flow: column;
		gap: 20px;
		div.notification {
			background-color: map-get($color-list, 'grey-light');
			padding: 10px;
			@include border-radius(10px);
			p {
				font-size: 1.5em;
				margin: 0;
				&.date {
					margin-top: 10px;
					font-size: 1em;
					font-weight: bold;
					text-transform: capitalize;
					color: map-get($color-list, 'black-light');
				}
				a {
					font-weight: bold;
					color: map-get($color-list, 'blue');
				}
			}
			div.button-group, div.transfer {
				margin-top: 10px;
				display: flex;
				flex-flow: row nowrap;
				gap: 10px;
				
			}

			div.transfer {
				select {
					background-color: map-get($color-list, 'white');
				}
				button {
					flex: 0 1 200px;
				}
				@include respond-to('small') {
					button {
						flex: 0 1 70px;
					}
				}
			}
		}
	}
}