@import "../style/color.scss";
@import "../style/breakpoints.scss";
@import "../style/variables.scss";

@mixin BodyFont {
	font-family: "Quicksand", sans-serif;
	font-size: 18px;
	font-weight: normal;
}

div.poster-extension {
	margin-bottom: 75px;
	div.header {
		display: flex;
		flex-flow: row nowrap;
		gap: 10px;
		margin-bottom: 10px;
		select {
			flex: 0 1 300px;
		}
		button {
			flex: 0 1 200px;
		}
		input {
			flex: 1 1 auto;
			&.tag {
				flex: 0 1 250px;
				@include respond-to("small") {
					flex-grow: 1;
				}
			}
		}
		@include respond-to("small") {
			flex-flow: row wrap;
			select,
			button {
				flex-grow: 1;
			}
		}
		label {
			display: flex;
			gap: 10px;
			span {
				display: block;
				line-height: 28px;
			}
		}
	}
	div.footer {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: map-get($color-list, "white");
		background-color: rgba(map-get($color-list, "white"), 0.8);
		padding: 10px 20px;
		margin: 0 auto;
		max-width: 992px;
		flex-flow: column;
		display: flex;
		z-index: 1;
		gap: 10px;
		@include respond-to("small") {
			select,
			button {
				flex-grow: 1;
			}
		}
		div.category {
			display: flex;
			flex-flow: row nowrap;
			gap: 10px;
		}
		div.tags {
			display: flex;
			flex-flow: row nowrap;
			gap: 10px;
			textarea {
				flex: 1 1 50%;
				display: block;
				box-sizing: border-box;
				border: none;
				outline: none;
				padding: 10px;
				-webkit-appearance: none;
				background-color: map-get($color-list, "grey-light");
				color: map-get($color-list, "blue");
				resize: none;
				@include BodyFont;
				@include border-radius(10px);
				font-weight: bold;
			}
			pre.rendered-tags {
				flex: 1 1 50%;
				display: block;
				box-sizing: border-box;
				padding: 10px;
				margin: 0;
			}
		}
	}
	div.posts {
		// background-color: map-get($color-list, 'grey-light');
		border-radius: 10px;
		display: grid;
		flex-flow: row wrap;
		grid-template-columns: repeat(6, 1fr);
		gap: 10px;
		@include respond-to("medium") {
			gap: 20px;
		}
		justify-content: center;
		@include respond-to("medium") {
			grid-template-columns: repeat(2, 1fr);
		}
		div.post {
			// border: 2px solid map-get($color-list, 'grey-dark');
			@include border-radius(10px);
			position: relative;
			overflow: hidden;
			display: flex;
			flex-flow: column;
			@include box-shadow(rgba(0, 0, 0, 0.16), 0px, 1px, 4px);
			div.wrap {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				display: flex;
				flex-flow: column;
				div.image,
				div.video {
					background-size: cover;
					position: relative;
					&::after {
						content: "";
						padding-top: 100%;
						display: block;
					}
				}
				div.link {
					background-color: map-get($color-list, "green");
					color: map-get($color-list, "white");
					padding: 10px;
				}
				gap: 10px;
				div.text {
					padding: 0 10px;
					&:first-child {
						margin-top: 10px;
					}
				}
				h4,
				blockquote {
					margin: 0;
				}
				p,
				strong {
					font-size: 0.7em;
					margin: 0;
				}
			}
			&::before {
				float: left;
				padding-top: 100%;
				content: "";
			}
			&::after {
				display: block;
				content: "";
				clear: both;
			}
			&.desktop:hover {
				> a.cover {
					display: block;
				}
			}
			a.cover {
				display: none;
				background-color: rgba(map-get($color-list, "green"), 0.7);
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 1;
				padding: 10px;
				font-weight: bold;
				text-decoration: none;
				color: map-get($color-list, "white");
				&.selected {
					display: block;
				}
			}
		}
	}
}
