@import "../style/color.scss";
@import "../style/breakpoints.scss";
@import "../style/variables.scss";

div.blog-display {
	div.preferences {
		margin-bottom: 20px;
		// border: 2px solid map-get($color-list, "grey-light");
		padding: 10px;
		@include border-radius(10px);
		display: flex;
		justify-content: center;
		flex-flow: row nowrap;
		div.cell {
			display: flex;
			flex-flow: column;
			label {
				margin-bottom: 10px;
			}
		}
	}
	div.blog-header {
		display: flex;
		flex-flow: column;
		align-items: center;
		gap: 20px;
		margin-bottom: 20px;
		div.blog-icon {
			background-size: cover;
			height: 175px;
			width: 175px;
			@include border-radius(10px);
		}
		h1 {
			margin: 0;
			a {
				color: map-get($color-list, "black");
			}
		}
		div.blog-actions {
			display: flex;
			flex-flow: row nowrap;
			gap: 20px;
		}
	}
	div.collapsible {
		// background-color: map-get($color-list, 'blue-light');
		background-color: map-get($color-list, "grey-light");
		// padding: 20px;
		margin-bottom: 20px;
		@include border-radius(10px);
		h2.collapsible-header {
			cursor: pointer;
			margin: 0;
			padding: 10px;
			background-color: map-get($color-list, "grey-light");
			@include border-radius(10px);
			&:hover {
				background-color: map-get($color-list, "grey");
			}
		}
		div.collapsible-body {
			padding: 20px;
			background-color: map-get($color-list, "blue-light");
			@include border-radius(10px);
		}
		&.collapsed {
			h2 {
				margin: 0;
			}
		}
	}
}
