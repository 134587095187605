@import "../style/color.scss";
@import "../style/breakpoints.scss";
@import "../style/variables.scss";

div.post-modal.modal {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: white;
	display: flex;
	flex-flow: column;
	div.modal-header {
		margin: auto;
		max-width: 540px;
		padding: 20px;
		width: 100%;
		div.modal-header-buttons {
			display: flex;
			flex-flow: row nowrap;
			gap: 20px;
		}
		div.rendered-tags {
			display: flex;
			flex-flow: row wrap;
			gap: 10px;
			margin-bottom: 10px;
			h3 {
				margin: 0;
				padding: 0;
			}
			pre {
				margin: 0;
				padding: 0;
			}
		}
	}
	div.modal-inner {
		flex: 1 0 600px;
		min-width: 0;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		padding: 0 20px 20px 20px;
		height: 100%;
		iframe {
			display: block;
			padding: 0px;
			border: none;
			height: 100%;
			justify-content: center;
			align-items: center;
			flex: 0 1 540px;
		}
	}
}

div.blog-queue {
	div.posts div.post {
		padding: 10px;
		font-size: 1.2em;
		display: flex;
		background-color: map-get($map: $color-list, $key: "white");
		gap: 20px;

		&:nth-child(even) {
			background-color: map-get($map: $color-list, $key: "grey-light");
		}
		&:first-child {
			@include border-radii(10px, 10px, 0, 0);
		}
		&:last-child {
			@include border-radii(0, 0, 10px, 10px);
		}
		&:only-child {
			@include border-radius(10px);
		}
		a {
			display: block;
		}
		div.post-inner {
			width: 100%;
			line-height: 43px;
			grid-template-columns: 1fr 50px 50px;
			@include respond-to("small") {
				grid-template-columns: 1fr 50px;
			}
			gap: 20px;
			display: grid;
			a.post-id {
				display: block;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			@include respond-to("small") {
				div.post-preview {
					display: none;
				}
			}
		}
	}
	div.posts {
		background-color: map-get($map: $color-list, $key: "white-dark");
		padding: 10px;
		@include border-radius(10px);
		> div.button-group {
			margin: 10px 0 0 0;
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			gap: 10px;
			span {
				line-height: 43px;
				@include respond-to("small") {
					display: none;
				}
			}
			button {
				flex: 1 1 200px;
			}

			&.header {
				justify-content: left;
				button {
					flex: 0 1 100px;
				}
				margin: 0 0 10px 0;
			}
		}
	}
	table.posts-table {
		table-layout: fixed;
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;
		thead tr {
			border-bottom: 5px solid map-get($map: $color-list, $key: "grey");
			&:first-child td:first-child {
				@include border-radii(10px, 0, 0, 0);
			}
			&:first-child td:last-child {
				@include border-radii(0, 10px, 0, 0);
			}
			td {
				font-size: 1.2em;
				padding: 20px 10px 20px 10px;
				font-weight: bold;
				background-color: map-get($map: $color-list, $key: "white");
			}
		}
		tbody {
			tr {
				background-color: map-get($map: $color-list, $key: "white");
				border-bottom: 5px solid map-get($map: $color-list, $key: "grey-light");
				&:nth-child(even) {
					// td {
					// 	background-color: map-get($map: $color-list, $key: "white");
					// }
				}
			}
		}
		tbody td {
			padding: 10px;
			a.post-id {
				display: block;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			div.button-group {
				display: flex;
				gap: 10px;
				button {
					flex: 0 0 50px;
				}
			}
			span.label {
				// backgr
				margin-top: 10px;
				display: inline-block;
				width: auto;
			}
		}
		td {
			&:nth-child(3) {
				width: 170px;
			}
			@include respond-to("small") {
				&:nth-child(1) {
					display: none;
				}
			}
		}

		// tbody tr:first-child td:first-child {
		// 	@include border-radii(10px, 0, 0, 0);
		// }
		// tbody tr:first-child td:last-child {
		// 	@include border-radii(0, 10px, 0, 0);
		// }
		tbody tr:last-child td:first-child {
			@include border-radii(0, 0, 0, 10px);
		}
		tbody tr:last-child td:last-child {
			@include border-radii(0, 0, 10px, 0);
		}
	}
}
