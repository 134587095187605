@import "./breakpoints.scss";

div.grid {
	display: flex;
	flex-direction: column;

	> div.content {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		width: 100%;
		gap: 20px;
		@include respond-to('small') {
			// display: block;
			flex-flow: column;
		}
		// &.medium {
		// 	@include respond-to('medium') {
		// 		display: block;
		// 	}
		// }

		> div.left {
			flex: 0 0 15em;
		}

		> div.right {
			flex: 1 1 auto;
		}

		> div.even {
			flex: 1 1 50%;
		}

		> div.column-3 {
			flex: 1 1 30%;
		}
		
		> div.column-7 {
			flex: 1 1 70%;
		}
		
		> div.column-5 {
			flex: 1 1 50%;
		}

		> div.column-4 {
			flex: 1 1 40%;
		}

		> div.column-6 {
			flex: 1 1 60%;
		}
	}
}

div.row {
	max-width: 900px;
	margin: auto;
	padding: 10px 20px 20px 20px;
}