@import "./style/color.scss";
@import "./style/grid.scss";
@import "./style/variables.scss";
@import "./style/breakpoints.scss";

@mixin BodyFont {
	font-family: "Quicksand", sans-serif;
	font-size: 18px;
	font-weight: normal;
}

html,
body {
	background-color: map-get($color-list, "white");
	margin: 0;
	padding: 0;
}

body {
	color: map-get($color-list, "black");
	background-color: map-get($color-list, "white");
	@include BodyFont();
}

a {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
	color: map-get($color-list, "red");
}

h1,
h2,
h3,
h4 {
	margin: 0 0 10px;
}

div#wrap {
	max-width: 992px;
	margin: auto;
	padding: 10px 20px 20px 20px;
	@include respond-to("medium") {
		padding: 20px;
	}
}

header {
	padding: 20px 0;
	position: relative;
	text-align: center;
	h1 {
		margin: 0 0 10px 0;
	}
	p {
		margin: 20px 0 0 0;
	}
	h1 {
		font-weight: normal;
		line-height: 20px;
		a {
			color: map-get($color-list, "red");
			@include respond-to("small") {
				font-size: 0.8em;
				padding: 5px 10px;
			}
		}
	}

	a.action {
		position: absolute;
		display: block;
		right: 0px;
		top: 10px;
		height: 20px;
		line-height: 20px;
		padding: 10px;
		font-weight: bold;
		@include border-radius(10px);
		@include respond-to("small") {
			display: none;
		}
	}
}

main {
	flex: 1 1 auto;
	display: flex;
	flex-flow: column;
}

main nav {
	margin-bottom: 20px;
	h3 {
		overflow: hidden;
	}
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		gap: 20px;
		justify-content: center;
		flex-flow: row nowrap;
		li {
			justify-content: center;
			display: flex;
			a {
				background-color: map-get($color-list, "grey-light");
				display: block;
				text-align: center;
				font-size: 1.5em;
				padding: 10px 20px;
				@include border-radius(10px);
				@include respond-to("small") {
					font-size: 1em;
					// padding: 5px 10px;
				}
			}
		}
	}
}

a.button,
button {
	display: block;
	box-sizing: border-box;
	text-align: center;
	border: none;
	outline: none;
	padding: 10px;
	width: 100%;
	cursor: pointer;
	font-weight: bold;
	@include border-radius(10px);
	@include BodyFont;
}

span.label {
	display: block;
	box-sizing: border-box;
	text-align: center;
	border: none;
	outline: none;
	padding: 10px;
	font-weight: bold;
	@include border-radius(10px);
	font-size: 0.8em;
	font-weight: bold;
}

select {
	display: block;
	box-sizing: border-box;
	text-align: center;
	border: none;
	outline: none;
	padding: 10px;
	-webkit-appearance: none;
	background-color: map-get($color-list, "grey-light");
	color: map-get($color-list, "blue");
	cursor: pointer;
	width: 100%;
	@include border-radius(10px);
	@include BodyFont;
	font-weight: bold;
}

input[type="text"] {
	display: block;
	box-sizing: border-box;
	border: none;
	outline: none;
	padding: 10px;
	width: 100%;
	-webkit-appearance: none;
	background-color: map-get($color-list, "grey-light");
	color: map-get($color-list, "blue");
	@include border-radius(10px);
	@include BodyFont;
	font-weight: bold;
}

div.panel {
	// background-color: map-get($color-list, "grey-light");
	flex-direction: column;
	display: flex;
	// padding: 20px;
	justify-content: center;
	@include border-radius(10px);
	margin-bottom: 20px;
	a.button {
		width: auto !important;
		&.tumblr {
			background-color: #35465c;
		}
	}
	&.buttons {
		gap: 20px;
	}
}

div.ui-selectable-helper {
	position: absolute;
	z-index: 10000000;
	border: 1px dotted black;
	border: 2px dashed map-get($color-list, "grey-light");
	background-color: map-get($color-list, "green");
	opacity: 0.5;
}

*.small {
	display: none;
	@include respond-to("small") {
		display: unset;
	}
}

*.desktop {
	display: unset;
	@include respond-to("medium") {
		display: none;
	}
}

*.tablet {
	display: unset;
	@include respond-to("small") {
		display: none;
	}
}

div.card {
	padding: 10px;
	@include border-radius(10px);
	h3 {
		margin: 0;
	}
}

div#subscribe {
	h3 {
		text-align: center;
	}
	div.upgrade-options {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		gap: 20px;
		align-items: flex-start;
		@include respond-to("medium") {
			display: block;
		}
		div.option {
			flex: 0 1 350px;
			padding: 10px;
			font-size: 1.2em;
			margin-bottom: 20px;
			p {
				margin: 0 0 10px 0;
			}
			@include border-radius(10px);
			border: 5px solid map-get($color-list, "grey");
			&.current {
				border-color: map-get($color-list, "green");
			}
		}
	}
}

div#dashboard {
	display: flex;
	flex-flow: row nowrap;
	gap: 20px;
	@include respond-to("small") {
		display: block;
	}
	div.column { 
		flex: 1 1 auto;
	}
	div.column.left {
		flex: 0 0 200px;
	}
	span.plan-identifier {
		color: map-get($color-list, "red");
		font-style: italic;
	}
}
