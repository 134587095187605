$color-list: (
	'black': #212121,
	'black-light': #484848,
	'black-dark': #000000,

	'blue': #2979ff,
	'blue-light': #75a7ff,
	'blue-dark': #004ecb,

	'blue-grey': #eceff1,
	'blue-grey-light': #ffffff,
	'blue-grey-dark': #babdbe,

	'cyan': #80deea,
	'cyan-light': #b4ffff,
	'cyan-dark': #4bacb8,

	'green': #00e676,
	'green-light': #66ffa6,
	'green-dark': #00b248,

	'grey-red': #ffebee,
	'grey-red-light': #ffffff,
	'grey-red-dark': #ccb9bc,

	'grey': #bdbdbd,
	'grey-light': #efefef,
	'grey-dark': #8d8d8d,

	'orange': #f9a825,
	'orange-light': #ffd95a,
	'orange-dark': #c17900,

	'purple': #e040fb,
	'purple-light': #ff78ff,
	'purple-dark': #a900c5,

	'red': #ff1744,
	'red-light': #ff616f,
	'red-dark': #c4001d,

	'yellow': #ffeb3b,
	'yellow-light': #ffff72,
	'yellow-dark': #c8b900,

	'white': #ffffff,
	'white-dark': #f5f5f5,

	'pink': #ff4081
);

@each $value in $color-list {
	.#{nth($value, 1)} {
	  color: nth($value, 2);
	}
  
	.background-#{nth($value, 1)} {
	  background-color: nth($value, 2) !important;
	}

	a.background-#{nth($value, 1)}, button.background-#{nth($value, 1)} {
		&:hover {
			background-color: darken(nth($value, 2), 10%) !important;
		}
	}
  
	.border-#{nth($value, 1)} {
	  border-color: nth($value, 2);
	}
}