@import "../style/color.scss";
@import "../style/breakpoints.scss";
@import "../style/variables.scss";

div.blog-grid {
	margin-bottom: 20px;
	padding: 20px;
	position: relative;
	@include border-radius(10px);
	background-color: map-get($color-list, "grey-light");
	div.blog-unlink {
		// margin-top: 20px;
		// font-weight: bold;
		// display: flex;
		// gap: 20px;
		width: 28px;
		height: 28px;
		position: absolute;
		top: -14px;
		right: -14px;
		button {
			padding: 5px;
			font-size: 0.8em;
			@include border-radius(50%);
		}
	}

	div.grid {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		@include respond-to("medium") {
			grid-template-columns: repeat(3, 1fr);
		}
		@include respond-to("xsmall") {
			grid-template-columns: repeat(2, 1fr);
		}
		gap: 20px;
	}
}

div.blog-card {
	min-width: 0;
	padding: 10px;
	display: flex;
	flex-direction: column;
	background-color: map-get($color-list, "white");
	@include border-radius(10px);
	a.blog-icon {
		display: block;
		flex: 1 1 50%;
		background-size: cover;
		@include border-radius(10px);
		&::after {
			content: "";
			display: block;
			padding-bottom: 100%;
		}
	}
	div.blog-name {
		a {
			margin: 10px 0 0 0;
			color: map-get($color-list, "black");
			font-weight: bold;
			text-align: center;
			max-width: 100%;
			display: block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	div.blog-buttons {
		display: flex;
		flex-flow: row nowrap;
		gap: 10px;
		a.button,
		button {
			flex: 1 1 auto;
		}
	}
}
