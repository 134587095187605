@import "../style/color.scss";
@import "../style/breakpoints.scss";
@import "../style/variables.scss";

div.blog-schedule {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	@include respond-to("medium") {
		grid-template-columns: repeat(2, 1fr);
	}
	@include respond-to("small") {
		grid-template-columns: repeat(1, 1fr);
	}
	gap: 20px;
	div.schedule-card {
		min-width: 0;
		background-color: map-get($color-list, "white");
		@include box-shadow(rgba(0, 0, 0, 0.16), 0px, 1px, 4px);
		padding: 10px;
		border-top: 50px solid map-get($color-list, "grey-light");
		@include border-radius(10px);
		flex: 1 1 auto;
		h3.schedule-day {
			margin-top: -50px;
			margin-bottom: 20px;
			height: 30px;
			line-height: 30px;
			div.schedule-day.switch {
				float: right;
				margin-top: 1px;
			}
		}
		div.schedule-items {
			display: flex;
			flex-flow: row nowrap;
			// align-items: center;
			text-align: center;
			justify-content: center;
			gap: 10px;
			select {
				text-align: center;
				width: 100%;
				margin-top: 10px;
			}
			div.schedule-item {
				flex: 1 1 auto;
			}
		}
		div.posts-per {
			margin-top: 10px;
			text-align: center;
			font-weight: bold;
		}
		div.schedule-buttons {
			display: flex;
			margin-top: 10px;
			gap: 10px;
		}
	}
}
